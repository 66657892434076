import React from "react";

import logoSvg from "./assets/logo.svg";
import classes from "./index.module.css";

const AuthLayout = ({ children }) => {
  return (
    <div className={classes.Wrapper}>
      <div className={classes.Photo} />

      <div className={classes.FormWrapper}>
        <img
          src={logoSvg} //
          alt="logo"
          className={classes.Logo}
        />

        <div className={classes.HeaderText}>
          <p>Система заявок</p>
          <p>фестивалей</p>
        </div>

        <div className={classes.CardWrapper}> {children}</div>

        <div className={classes.CopyRight}>
          Copyright © Krasnodarskye Lososi 2020–2025
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
